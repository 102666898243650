import { useQuery } from "@tanstack/vue-query";
import { IDataResponse } from "~/types/schema";

export const useCheckLocation = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<IDataResponse<{ isBlocked: true }>, Error, boolean>({
    queryKey: ["useCheckLocation"],
    queryFn: () => {
      return $api2("/api/countries/is-blocked");
    },
    select: (res) => (res.data.data as any)?.is_blocked,
  });
};
