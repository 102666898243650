import { defineStore } from "pinia";

export const useCheckLocationStore = defineStore(
  "useCheckLocationStore",
  () => {
    // undefined mean not fetched yet
    const isBlock = ref<boolean>();
    return {
      isBlock,
    };
  }
);
