<template>
  <slot />
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ROUTES_NAME } from "~/config/router";
import { useCheckLocation } from "~/queries/common";
import { useCheckLocationStore } from "~/stores/checkLocationStore";

const { data } = useCheckLocation();
const { isBlock } = storeToRefs(useCheckLocationStore());
const token = useToken();
const route = useRoute();
watch(
  data,
  (value) => {
    isBlock.value = value;
    if (value) {
      token.value = null;
      navigateTo({ path: ROUTES_NAME.NOT_AVAILABLE, replace: true });
    } else if (route.path === ROUTES_NAME.NOT_AVAILABLE) {
      navigateTo({
        path: ROUTES_NAME.LOBBY,
        replace: true,
        query: route.query,
      });
    }
  }
);
</script>
