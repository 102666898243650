import dayjs from "dayjs";
import "dayjs/locale/en";

export const initializeDayjsLocale = async (newLocale?: string | null) => {
  if (newLocale) {
    try {
      switch (newLocale) {
        case "es":
          await import("dayjs/locale/es");
          break;
        case "de":
          await import("dayjs/locale/de");
          break;
        case "fr":
          await import("dayjs/locale/fr");
          break;

        case "nl":
          await import("dayjs/locale/nl");
          break;
        case "pt":
          await import("dayjs/locale/pt");
          break;
        case "tr":
          await import("dayjs/locale/tr");
          break;
        case "ja":
          await import("dayjs/locale/ja");
          break;
        default:
        case "en":
        case undefined:
          break;
      }

      dayjs.locale(newLocale);
    } catch (error: any) {
      //
    }
  }
};
