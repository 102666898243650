import { defineNuxtPlugin } from "#app";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";

export default defineNuxtPlugin({
  name: "vuetify",
  parallel: true,
  setup: (nuxtApp) => {
    const vuetify = createVuetify({
      icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
          mdi,
        },
      },
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            colors: {
              error: "#EF3054",
              background: "#080808",
            },
          },
        },
      },
      ssr: true,
      defaults: {
        global: {
          eager: false,
        },

        VDataTable: {
          itemsPerPage: -1,
        },
      },
    });

    nuxtApp.vueApp.use(vuetify);
  },
});
