import { defineNuxtPlugin } from "#imports";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import relative from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";

export default defineNuxtPlugin({
    name: "fetch",
    setup: () => {
        dayjs.extend(duration);
        dayjs.extend(utc);
        dayjs.extend(relative);
        dayjs.extend(advancedFormat);
    },
});
