import axios from "axios";
import {
  userAccess,
  userRegister,
  userAccessSocial,
  email,
  resetPassword,
  emailVerificationRequest,
  updatePersonalDetailRequest,
  updatePasswordRequest,
} from "~/types/schema";

export const loginUser = (userRequest: userAccess) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/login`,
    method: "post",
    data: userRequest,
  });
};

export const telegramLogin = (userRequest) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/telegram/login`,
    method: "post",
    data: userRequest,
  });
};

export const logoutUser = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/logout`,
    method: "get",
  });
};

export const fetchUser = () => {
  const { $api2 } = useNuxtApp();
  return $api2(`/api/user/information`);
};

export const registerUser = (userRegister: userRegister) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/register`,
    method: "post",
    data: userRegister,
  });
};

export const resendTokenEmail = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/resend-token`,
    method: "get",
  });
};

export const socialLogin = (userSocial: userAccessSocial) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/social/login`,
    method: "post",
    data: userSocial,
  });
};

export const forgotPassword = (email: email) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/forgot-password`,
    method: "post",
    data: email,
  });
};

export const resetPass = (resetPassword: resetPassword) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/reset-password`,
    method: "post",
    data: resetPassword,
  });
};

export const resendEmailVerify = (email: email) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/email/resend`,
    method: "post",
    data: email,
  });
};

export const emailVerification = (
  emailVerification: emailVerificationRequest
) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/email-verification?id=${emailVerification.id}&hash=${emailVerification.hash}`,
    method: "get",
  });
};

export const locationCountries = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/location/countries`,
    method: "get",
  });
};

export const locationStates = (countryCode: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/location/states?country_code=${countryCode}`,
    method: "get",
  });
};

export const locationCities = (stateId: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/location/cities?state_id=${stateId}`,
    method: "get",
  });
};

export const updatePersonalDetail = (
  updatePersonalDetail: updatePersonalDetailRequest
) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/personal-detail/update`,
    method: "patch",
    data: updatePersonalDetail,
  });
};

export const sendOtp = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/otp/resend`,
    method: "get",
  });
};

export const verifyOtp = (otp: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/verify?code=${otp}`,
    method: "get",
  });
};

export const updateInfoUser = (userInfo: updatePersonalDetailRequest) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/information/update`,
    method: "patch",
    data: userInfo,
  });
};

export const getReferralFriend = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/referral-friends/info`,
    method: "get",
  });
};

export const rewardIsClaim = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/login-reward/is-claimed`,
    method: "get",
  });
};

export const rewardClaim = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/login-reward/claim`,
    method: "get",
  });
};

export const updatePassword = (
  updatePasswordRequest: updatePasswordRequest
) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/change-password`,
    method: "PATCH",
    data: updatePasswordRequest,
  });
};

export const starInformation = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/star-information`,
    method: "GET",
  });
};

export const listLevel = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/levels`,
    method: "GET",
  });
};

export const vipSystemStar = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/settings/vip-system/star`,
    method: "GET",
  });
};

export const updateEmail = (req: any) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/social/email/update`,
    method: "PATCH",
    data: req,
  });
};

export const levelInformation = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/level-information`,
    method: "GET",
  });
};

export const cashback = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/cashback`,
    method: "GET",
  });
};

export const claimCashback = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/cashback/claim`,
    method: "POST",
  });
};

export const getInfoUserGoogle = (access_token: string) => {
  return axios.get(
    `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`
  );
};

export const changeLanguage = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/lang/change`,
    method: "patch",
  });
};
