import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import { storeToRefs } from "pinia";
import { ROUTES_NAME } from "~/config/router";
import { useCheckLocationStore } from "~/stores/checkLocationStore";

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return;
  const token = useToken();
  const { isBlock } = storeToRefs(useCheckLocationStore());
  if (isBlock.value && to.path !== ROUTES_NAME.NOT_AVAILABLE) {
    token.value = null;
    return navigateTo({
      path: ROUTES_NAME.NOT_AVAILABLE,
      replace: true,
    });
  }
  if (isBlock.value === false && to.path === ROUTES_NAME.NOT_AVAILABLE) {
    return navigateTo({
      path: ROUTES_NAME.LOBBY,
      replace: true,
    });
  }
});
