export const configEnum = {
  COOKIE_EXPIRED: 604800,
  AUTH_COOKIE_EXPIRED: 86400,
  BREAK_POINT_SCREEN: 1280,
  GAME_MOBILE_SCREEN: 425,
};

export const enum GENDER {
  FEMALE = "female",
  MALE = "male",
  OTHER = "other",
}

export const enum DISPLAY_SECTION {
  IN_MENU = "in_menu",
  IN_LOBBY = "in_lobby",
  IN_TOP_LOBBY = "in_top_lobby",
  IN_BOTTOM_LOBBY = "in_bottom_lobby",
  IN_LOBBY_NAV = "in_lobby_nav",
  IN_CASINO_FOOTER = "in_casino_footer",
  IN_LIVE_CASINO_FOOTER = "in_live_casino_footer",

  IN_TOP_LIVE_CASINO = "in_top_live_casino",
  IN_BOTTOM_LIVE_CASINO = "in_bottom_live_casino",
  IN_LIVE_CASINO_NAV = "in_live_casino_nav",
  IN_LIVE_CASINO = "in_live_casino",
}

export const enum DISPLAY_STATUS {
  LOGGED_IN = "logged_in",
  LOGGED_OUT = "logged_out",
}

export const enum TYPE_LIVE_WIN {
  LIVE = "live",
  WEEK = "week",
  DAY = "day",
}

export const enum PROMOTION_TYPE {
  REFERRAL_REWARD = "referral_reward",
  MONTHLY_REFERRAL_REWARD = "monthly_referral_reward",
  EMAIL_DRAW = "email_draw",
  PRIZE_DROP = "prize_drop",
}

export const enum PAGES {
  ABOUT_US = "about-us",
  RESPONSIBLE_SOCIAL_GAMING = "responsible-gaming",
  TERMS_AND_CONDITIONS = "terms-and-conditions",
  PRIVACY_POLICY = "privacy-policy",
  FAQS = "faqs",
  VPN_INSTRUCTION = "vpn-instruction",
  CONTACTS = "contacts",
}

export const enum GAME_TRANSACTION_TYPE {
  GAME_ROLLBACK = "game_rollback",
  GAME_BET = "game_bet",
  GAME_WIN = "game_win",
}

export const enum ALERT_TYPE {
  WALLET_BALANCE = "wallet_balance",
  UPDATE_AVAILABLE_BONUS = "update_available_bonus",
  UPDATE_FINISH_BONUS = "update_finish_bonus",
  UPDATE_BONUS_ITEM = "update_bonus_item",
  NEW_NOTIFICATION = "new_notification",
  VERIFIED_EMAIL = "verified_email",
  VERIFIED_OTP = "verified_otp",
  ALL_WALLET_BALANCES = "all_wallet_balances",
  READY_CLAIM_CASHBACK = "ready_claim_cashback",
  UPDATE_RATE = "update_rate",
  TRANSACTION_LATEST_BET = "transaction_latest_bet",
  TRANSACTION_HIGH_ROLLER_BET = "transaction_high_roller_bet",
  PLAYER_PENDING_DEPOSIT = "player_pending_deposit",
  PLAYER_DEPOSIT = "player_deposit",
  LEVEL_PROGRESS = "level_progress",
}

export const enum RESPONSE_STATUS {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export const enum PLAYGAME_TYPE {
  REAL = "real",
  FUN = "fun",
}

export const enum TRANSACTION_TYPE {
  DEPOSIT = "deposit",
  WITHDRAWAL = "withdraw",
}

export const enum TRANSACTION_STATUS {
  PAID_OUT = "paid_out",
  PENDING = "pending",
}

export const enum USER_REQUEST_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  CANCELED = "canceled",
  REJECTED = "rejected",
  PAYOUT_PENDING = "payout_pending",
  FAIL = "fail",
}

export const enum WALLET_TRANSACTION_ACTION {
  DEPOSIT = "Deposit",
  WITHDRAW = "Withdraw",
  BUY_CRYPTO = "Buy Crypto",
  SETTING = "Setting",
}

export const enum BONUS_TAB_TYPE {
  AVAILABLE = "Available",
  ACTIVE = "Active",
  HISTORY = "History",
}

export const enum WALLET_NETWORK_KIND {
  XRP = "XRP",
  BTC_LIGHTNING = "BTC_LIGHTNING",
}

export const enum SYMBOLS {
  EUR = "EUR",
  USD = "USD",
}

export const enum BONUS_STATUS {
  AVAILABLE = "available",
  CLAIMED = "claimed",
  ACTIVE = "active",
  COMPLETED = "completed",
  EXPIRED = "expired",
  BUSTED = "busted",
  UNCLAIMED = "unclaimed",
}

export const enum BONUS_TYPE {
  CASH_BACK_BONUS = "cash_back_bonus",
  WELCOME_BONUS_OFFER = "welcome_bonus_offer",
  DEPOSIT_BONUS_OFFER = "deposit_bonus_offer",
  FREE_SPINS_OFFER = "free_spins_offer",
}

export const enum CLAIM_TYPE {
  AVAILABLE,
  CLAIMED,
  REJECTED = -1,
}

export const enum NUMBERIC_TYPE {
  DOT,
  WITHOUT_DOT,
}

export const enum LEVEL {
  NO_LEVEL = 0,
  PIXEL_PIONEER = 1,
  BYTE_BANDIT = 2,
  TOKEN_TOSSER = 3,
  CHAIN_COMMANDER = 4,
  PUNK_OVERLORD = 5,
}

export const enum TRANSACTION_LOBBY {
  USER_LASTEST_BET = "user_lastest_bet",
  LASTEST_BET = "lastest_bet",
  HIGH_ROLLER = "high_roller",
}

export const enum PROVIDER_TYPE_FILTER {
  TYPE_NORMAL = "normal",
  TYPE_FAVORITES = "favorites",
  TYPE_PLAY_AGAIN = "play-again",
}

export const enum CHALLENGE_TYPE {
  LIVE = "live",
  FINISHED = "finished",
  USER_COMPLETED = "completed",
}

export const enum TOURNAMENT_CATEGORIES {
  LIVE = "live",
  FINISHED = "finished",
  USER_COMPLETED = "completed",
}

export const enum LEADERBOARD_TYPE {
  CASH = "cash",
  FREE_SPIN = "free_spin",
}

export const enum PROMOTION_AMOUNT_TYPE {
  CASH = "cash",
  FREE_SPIN = "free_spin",
}

export const enum TOURNAMENT_TYPE {
  HIGHEST_MULTIPLIER = "highest_multiplier",
  HIGHEST_POINT = "highest_point",
  RANDOM = "random",
  WAGERING_POINT = "wagering_point",
  WAGERING_POINT_RANDOM = "wagering_point_random",
}

export const enum TOURNAMENT_GAME_SCOPE {
  SINGLE_GAMES = "single_games",
  PROVIDERS = "providers",
}

export const enum PLAY_STATUS {
  CAN_PLAY = "CAN_PLAY",
  LOCATION_BLOCKED = "LOCATION_BLOCKED",
  HIDDEN_WHEN_BONUS = "HIDDEN_WHEN_BONUS",
  INACTIVE = "INACTIVE",
}
export const enum QUERY_PARAMS {
  OPEN_DEPOSIT = "open-deposit",
  TXTBONUSCODE = "txtbonuscode",
}

export const enum SOCIAL_PROVIDER {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  TELEGRAM = "telegram",
  EMAIL = "email",
}
