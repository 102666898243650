import { NUMBERIC_TYPE } from "~/config/enum";

function setFocusAt(element: any, start: any, end?: any) {
    if (typeof end === "undefined") {
        end = start;
    }

    // Chắc chắn rằng start và end không vượt qua độ dài của giá trị trong input
    start = Math.max(0, Math.min(start, element.value.length));
    end = Math.max(0, Math.min(end, element.value.length));

    // Đặt focus tại vị trí đã chọn
    element.focus();
    element.setSelectionRange(start, end);
}

export default {
    mounted(el: any, binding: any) {
        const directiveType =
            binding.value === NUMBERIC_TYPE.DOT
                ? NUMBERIC_TYPE.DOT
                : NUMBERIC_TYPE.WITHOUT_DOT;
        el.addEventListener("input", (event: any) =>
            handleInputNumber(event, directiveType)
        );
        el.addEventListener("keypress", (event: any) =>
            handleKeyPressNumber(event, directiveType)
        );
    },
    unmounted(el: any) {
        el.removeEventListener("input", handleInputNumber);
        el.removeEventListener("keypress", handleKeyPressNumber);
    },
};

const handleInputNumber = (event: any, directiveType: NUMBERIC_TYPE) => {
    const dotRegex = directiveType === NUMBERIC_TYPE.DOT ? /[^\d.]/g : /[^\d]/g;
    const oldValue = event.target.getAttribute("data-old-value");
    const firstDotIndex = event.target.value.indexOf(".");

    // Loại bỏ mọi kí tự không phải là số hoặc dấu chấm
    let newValue = event.target.value.replace(dotRegex, "");

    // Kiểm tra xem dấu chấm có đứng ở đầu không
    if (newValue.startsWith(".")) {
        newValue = newValue.substring(1);
    }

    // Kiểm tra nếu có dấu chấm thứ hai và xử lý
    const secondDotIndex = newValue.indexOf(".", firstDotIndex + 1);
    if (secondDotIndex !== -1) {
        if (oldValue?.indexOf(".") > firstDotIndex) {
            // Nếu dotB được nhập phía trước dotA, loại bỏ dotA
            newValue =
                newValue.substring(0, secondDotIndex) +
                newValue.substring(secondDotIndex + 1);
            event.target.value = newValue;
            event.target.dispatchEvent(new Event("input", { bubbles: true }));
            const dotIndex = event.target.value?.indexOf(".");
            if (dotIndex !== -1) {
                setFocusAt(event.target, dotIndex + 1);
            }
        } else {
            // Nếu dotB được nhập phía sau dotA, loại bỏ dotA
            newValue =
                newValue.substring(0, firstDotIndex) +
                newValue.substring(firstDotIndex + 1);
            event.target.value = newValue;
            event.target.dispatchEvent(new Event("input", { bubbles: true }));
            const dotIndex = event.target.value?.indexOf(".");
            if (dotIndex !== -1) {
                setFocusAt(event.target, dotIndex + 1);
            }
        }
    }

    // Cập nhật giá trị mới vào thuộc tính data
    event.target.setAttribute("data-old-value", event.target.value);

    // Cập nhật giá trị của input field
    if (newValue !== event.target.value) {
        event.target.value = newValue;
        event.target.dispatchEvent(new Event("input", { bubbles: true }));
    }
};

const handleKeyPressNumber = (event: any, directiveType: NUMBERIC_TYPE) => {
    const listAllow = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (directiveType === NUMBERIC_TYPE.DOT) {
        listAllow.push(".");
    }
    const { key } = event;
    if (!listAllow.includes(key)) {
        event.preventDefault();
    }
    return true;
};
