<template>
  <div class="h-dvh overflow-hidden">
    <v-app id="app" class="h-full overflow-auto">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </v-app>
    <!--    <NuxtLoadingIndicator />-->
    <DelayHydration>
      <LazyClientOnly>
        <LazyLiveChat />
        <LazyTelegram />
        <CheckLocation />
      </LazyClientOnly>
    </DelayHydration>
    <MetaTag />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import CheckLocation from "~/components/layouts/CheckLocation.vue";
import MetaTag from "~/components/layouts/MetaTag.vue";
import { useCurrentLanguage, useLanguages } from "~/composables/useLanguages";
import { useToken } from "~/composables/useToken";
import { formatLanguageCode } from "./utils";

const token = useToken();
const lang = useCurrentLanguage();
const listLanguages = useLanguages();

initializeDayjsLocale(lang.value);
const initialLanguage = () => {
  if (!token.value && !lang.value) {
    const systemLanguage = formatLanguageCode(
      window.Telegram.WebApp?.initDataUnsafe?.user?.language_code ||
        navigator.language
    );
    lang.value = listLanguages.value.find(
      (language) => language.value === systemLanguage
    )
      ? systemLanguage
      : "en";
  }
};

const route = useRoute();
onMounted(() => {
  if (typeof window.trackConversion === "function") {
    window.trackConversion();
  }
  initialLanguage();
  watch(
    () => route.path,
    () => {
      scrollToTop();
    }
  );
});
</script>
