import { defineNuxtPlugin, useRuntimeConfig, navigateTo } from "#imports";
import axios from "axios";
import { RESPONSE_STATUS } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { deleteOldCookie } from "~/utils";


export default defineNuxtPlugin({
  name: "fetch",
  setup: () => {
    const config = useRuntimeConfig();
    const getApiUrl = () => {
      const baseUrl = config.public.BASE_URL_API;
      if (typeof baseUrl !== "string") {
        throw new Error("BASE_URL_API must be a string");
      }
      return `${baseUrl}`;
    };

    const api = axios.create({
      baseURL: getApiUrl(),
      timeout: 50000,
    });

    api.interceptors.request.use(
      (config) => {
        const tokenCookie = useCustomCookie("token");
        const currentLang = useCustomCookie("lang");
        const rawHeaders = useRequestHeaders();
        if (rawHeaders["x-forwarded-for"]) {
            config.headers["X-Forwarded-For"] = rawHeaders["x-forwarded-for"];
            config.headers["Forwarded-Header-2"] = rawHeaders["x-forwarded-for"];
        }

        config.headers["X-localization"] = currentLang.value;
        config.headers["X-Domain"] = window.location.hostname;
        if (tokenCookie.value) {
          config.headers["Access-Control-Allow-Origin"] = "*";
          config.headers["Authorization"] = `Bearer ${tokenCookie.value}`;
          config.headers["Accept"] = "application/json";
        }
        return config;
      },
      (error) => {
        Promise.reject(error.response);
      }
    );

    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.code == "ERR_NETWORK") {
          return Promise.reject(error);
        }

        const { status } = error.response;
        if (status === RESPONSE_STATUS.UNAUTHORIZED) {
          // clear token in coookie then redirect to login
          const tokenCookie = useCustomCookie("token");
          tokenCookie.value = null;

          deleteOldCookie("token");

          const pathLogin = `${ROUTES_NAME.LOGIN}?backToUrl=${encodeURIComponent(location.pathname + location.search)}`;
          const route = isWebApp() ? ROUTES_NAME.LOBBY : pathLogin;

          await navigateTo({ path: route }, { external: true });
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    return {
      provide: {
        api,
      },
    };
  },
});
