import { defineNuxtPlugin } from "#app";
import {
  fpjsPlugin,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-vue-v3";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const endPoint =
    config.public.FINGERPRINT_END_POINT || FingerprintJSPro.defaultEndpoint;
  const scriptUrlPattern =
    config.public.FINGERPRINT_SCRIPT_URL_PATTERN ||
    FingerprintJSPro.defaultScriptUrlPattern;

  nuxtApp.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: config.public.FINGERPRINT_PUBLIC_KEY,
      region: config.public.FINGERPRINT_REGION,
      endpoint: [endPoint, FingerprintJSPro.defaultEndpoint],
      scriptUrlPattern: [
        scriptUrlPattern,
        FingerprintJSPro.defaultScriptUrlPattern,
      ],
    },
  });
});
