import { defineNuxtPlugin, useState } from "#imports";
import type {
  DehydratedState,
  VueQueryPluginOptions,
} from "@tanstack/vue-query";
import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate,
} from "@tanstack/vue-query";

export default defineNuxtPlugin({
  name: "vue-query",
  parallel: true,
  setup: (nuxt) => {
    const vueQueryState = useState<DehydratedState | null>("vue-query");

    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 8000,
          retry: 0,
          retryOnMount: false,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        },
      },
    });
    const options: VueQueryPluginOptions = { queryClient };

    nuxt.vueApp.use(VueQueryPlugin, options);

    if (import.meta.server) {
      nuxt.hooks.hook("app:rendered", () => {
        vueQueryState.value = dehydrate(queryClient);
      });
    }

    if (import.meta.client) {
      hydrate(queryClient, vueQueryState.value);
    }
  },
});
