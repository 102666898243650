import {
  defineNuxtPlugin,
  useRuntimeConfig,
  deleteOldCookie,
  navigateTo,
} from "#imports";
import { RESPONSE_STATUS } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";

export default defineNuxtPlugin({
  name: "fetch",
  dependsOn: ["pinia"],
  setup: () => {
    const config = useRuntimeConfig();

    const api2 = $fetch.create({
      baseURL: config.public.BASE_URL_API,
      onRequest: ({ options }) => {
        const tokenCookie = useToken();
        const currentLang = useCurrentLanguage();
        const url = useRequestURL();
        if (import.meta.server) {
          const rawHeaders = useRequestHeaders();
          if (rawHeaders["x-forwarded-for"])
            options.headers.append(
              "X-Forwarded-For",
              rawHeaders["x-forwarded-for"]
            );
            options.headers.append(
                "Forwarded-Header-2",
                rawHeaders["x-forwarded-for"]
            );
        }
        options.headers.append("Accept", "application/json");
        options.headers.append("Access-Control-Allow-Origin", "*");
        options.headers.append("X-localization", currentLang.value);
        options.headers.append("X-Domain", url.hostname);
        if (tokenCookie.value) {
          options.headers.append(
            "Authorization",
            `Bearer ${tokenCookie.value}`
          );
        }
      },

      onResponseError: async ({ error }) => {
        const url = useRequestURL();
        if (error.code == "ERR_NETWORK") {
          return Promise.reject(error);
        }

        const { status } = error.response;
        if (status === RESPONSE_STATUS.UNAUTHORIZED) {
          const tokenCookie = useCustomCookie("token");
          tokenCookie.value = null;

          deleteOldCookie("token");

          const pathLogin = `${ROUTES_NAME.LOGIN}?backToUrl=${encodeURIComponent(url.pathname + url.search)}`;
          const route = isWebApp() ? ROUTES_NAME.LOBBY : pathLogin;

          await navigateTo({ path: route }, { external: true });
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    });

    return {
      provide: {
        api2,
      },
    };
  },
});
