<template >
    <slot />
</template>
<script setup lang="ts">
import { useRequestURL } from "nuxt/app";
import {siteInfo} from "~/config/siteInfo";

const lang= useCurrentLanguage()
const url = useRequestURL();
const hostname = siteInfo.sites[url.hostname]
    ? url.hostname
    : "casinopunkz.io";
const site = siteInfo.sites[hostname];

useHead({
    htmlAttrs: {
        lang: lang.value || 'en'
    },
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: '/favicon.ico'
        }
    ],
    script: [
        ...(siteInfo.sites.hasOwnProperty(url.hostname)
        ? [
            {
                src: siteInfo.sites[url.hostname]?.umami_website_url,
                defer: true,
                'data-website-id': siteInfo.sites[url.hostname]?.umami_website_id,
            },
            ]
        : []),
        // Other scripts
        // {
        //     src: 'https://example.com/other-script.js',
        //     defer: true,
        // },
    ],
})

useSeoMeta({
    title: site.title,
    description: site.description,
    ogImageHeight: 415,
    ogImageWidth: 310,
    ogType: 'website',
    ogSiteName: site.title,
    ogTitle: site.title,
    ogImage: `${url.origin}/images/background.webp`,
    ogUrl: `${url.origin}/`,
    ogLocale: siteInfo.locale,
    ogDescription: site.description,
    twitterTitle: site.title,
    twitterDescription: site.description,
    twitterImage: `${url.origin}/images/background.webp`,
    twitterCard: "summary_large_image",
    author: siteInfo.author,
})
</script>
