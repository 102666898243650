import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const tokenCookie = useCustomCookie("token");
    const initEcho = () => {
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: config.public.BROADCAST_DRIVER,
            key: config.public.PUSHER_APP_KEY,
            cluster: config.public.PUSHER_APP_CLUSTER,
            forceTLS: false,
            authEndpoint: config.public.PUSHER_BROASTCAST_URL,
            auth: {
                headers: {
                    Authorization: "Bearer " + tokenCookie.value,
                },
            },
        });
    };

    initEcho();

    watch(tokenCookie, (current) => {
        if (current) {
            window.Echo.options.auth.headers.Authorization =
                "Bearer " + current;
        }
    });
});
