import { navigateTo, useNuxtApp } from "#app";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import { configEnum, QUERY_PARAMS } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import {
  cashback,
  changeLanguage,
  forgotPassword,
  loginUser,
  telegramLogin,
  fetchUser,
  registerUser,
  socialLogin,
  resendEmailVerify,
  emailVerification,
  resetPass,
  locationCountries,
  locationStates,
  locationCities,
  updatePersonalDetail,
  logoutUser,
  sendOtp,
  verifyOtp,
  updateInfoUser,
  getReferralFriend,
  rewardIsClaim,
  updatePassword,
  starInformation,
  listLevel,
  vipSystemStar,
  updateEmail,
  levelInformation,
} from "~/service/authService";
import { useSystemStore } from "~/stores/systemStore";
import { useWalletStore } from "~/stores/walletStore";
import {
  userRegister,
  userAccessSocial,
  email,
  resetPassword,
  updatePersonalDetailRequest,
  updatePasswordRequest,
  cashbackInfo,
  telegramData,
} from "~/types/schema";
import { deleteOldCookie, useCustomCookie } from "~/utils";

export const useAuthStore = defineStore("useAuthStore", {
  state: () => {
    return {
      user: {} as any,
      token: "",
      email: "",
      emailLogging: "",
      tokenSocialLogging: "",
      socialLogging: "",
      backToUrl: "",
      cashbackInfo: {} as cashbackInfo,
      levelInfo: {} as any,
    };
  },

  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getCheck: (state) => !_isEmpty(state.user),
    getEmail: (state) => state.email,
    getCashbackInfoState: (state) => state.cashbackInfo,
  },
  actions: {
    saveEmailLogging(email: string) {
      this.emailLogging = email;
    },

    setBackToUrl(url: string) {
      this.backToUrl = url;
    },

    saveToken(token: string, remember: number) {
      this.token = token;
      const cookie = useToken();
      cookie.value = token;
    },
    clearVoucherCode() {
      const voucherCode = useCustomCookie(QUERY_PARAMS.TXTBONUSCODE);
      voucherCode.value = null;

      deleteOldCookie(QUERY_PARAMS.TXTBONUSCODE);
    },

    async loginUser(email: string, password: string) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      const dataRequest = { username_or_email: email, password };
      try {
        const res = await loginUser(dataRequest);
        if (res.data.success) {
          this.saveToken(res.data.data.token, configEnum.AUTH_COOKIE_EXPIRED);
          // await this.fetchUser();
          await navigateTo(this.backToUrl || ROUTES_NAME.LOBBY, {
            replace: true,
          });
        } else {
          if (!res.data.data.email_verified_at) {
            this.emailLogging = res.data.data.email;
            const useSystem = useSystemStore();
            useSystem.changeUnVerifyEmailDialog(true);
          }
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }
      loader.hide();
    },

    async telegramLogin(userData: telegramData) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await telegramLogin(userData);
        if (res.data.success) {
          this.saveToken(res.data.data.token, configEnum.AUTH_COOKIE_EXPIRED);
          await navigateTo({
            path: ROUTES_NAME.LOBBY,
            query: useRoute().query,
            force: true,
          });
          const useWallet = useWalletStore();
          await Promise.all([useWallet.setListWallet(), this.fetchUser()]);
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }
      loader.hide();
    },

    async logout() {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await logoutUser();
        if (res.data.success) {
          this.user = null;
          const tokenCookie = useCustomCookie("token");
          const remberWallet = useCustomCookie("remeberWallet");
          tokenCookie.value = null;
          remberWallet.value = null;
          await navigateTo(
            { path: ROUTES_NAME.LOBBY },
            { external: true, replace: true }
          );
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }
      loader.hide();
    },

    async fetchUser() {
      try {
        const res = await fetchUser();
        if (res.success) this.user = res.data;
        if (this.user.lang) {
          const currentLang = useCustomCookie("lang", {
            expires: dayjs().add(6, "months").toDate(),
          });
          currentLang.value = this.user.lang;
        }
      } catch (e: any) {
        //remove cookie
        const tokenCookie = useCustomCookie("token");
        tokenCookie.value = null;

        //remove user redirect to login
        this.user = null;
        const route = isWebApp() ? ROUTES_NAME.LOBBY : ROUTES_NAME.LOGIN;
        await navigateTo({ path: route });
      }

      return true;
    },

    async registerUser(
      username: string,
      email: string,
      password: string,
      visitor_id: string,
      voucher_code: string
    ) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const referenceIdCookie = useCustomCookie("reference_id");
      const affiliateTokenCookie = useCustomCookie("affiliate_token");

      const reference = referenceIdCookie.value || null;
      const affiliate_token = affiliateTokenCookie.value || null;

      const dataRequest: userRegister = {
        username,
        email,
        password,
        reference,
        affiliate_token,
        visitor_id,
        voucher_code,
      };

      try {
        const res = await registerUser(dataRequest as userRegister);
        if (res.data.success) {
          this.clearVoucherCode();
          await navigateTo({
            path: ROUTES_NAME.SIGN_UP_WELCOME,
            query: {
              email,
            },
          });
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }

      loader.hide();
    },

    async loginUserSocial(
      provider_name: string,
      access_token: string,
      email = "",
      visitor_id?: string
    ) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const referenceIdCookie = useCustomCookie("reference_id");
      const affiliateTokenCookie = useCustomCookie("affiliate_token");

      const reference = referenceIdCookie.value || null;
      const affiliate_token = affiliateTokenCookie.value || null;
      const dataRequest: userAccessSocial = {
        provider_name,
        access_token,
        reference,
        affiliate_token,
        visitor_id,
      };

      try {
        const res = await socialLogin(dataRequest);

        if (res.data.success && !!res.data.data.token) {
          this.saveToken(res.data.data.token, res.data.data.expires_in);
          await this.fetchUser();
          await navigateTo({
            path: ROUTES_NAME.LOBBY,
          });
        } else if (res.data?.data?.email_verified_at === null) {
          this.emailLogging = res.data.data.email;
          const useSystem = useSystemStore();
          useSystem.changeUnVerifyEmailDialog(true);
        } else {
          this.tokenSocialLogging = access_token;
          this.socialLogging = provider_name;
          await navigateTo({
            path: ROUTES_NAME.UPDATE_MAIL,
            query: {
              tokenSocialLogging: access_token,
              socialLogging: provider_name,
              email: email,
            },
          });
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }

      loader.hide();
    },

    async resendVerifyMail(email: string, showNoti = true) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const resendRequest: email = { email };
      try {
        const res = await resendEmailVerify(resendRequest);
        if (showNoti)
          if (res.data.success) {
            (useNuxtApp() as any).$toast.success(res.data.data);
          } else {
            (useNuxtApp() as any).$toast.error(res.data.message);
          }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response.data?.message || e.message
        );
        return false;
      } finally {
        loader.hide();
      }
      return true;
    },

    async emailVerification(id: string, hash: string) {
      try {
        const res = await emailVerification({ id, hash });
        return res;
      } catch (e: any) {
        return e.response;
      }
    },

    async forgotPassword(email: string) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const emailRequest: email = { email };
      try {
        const res = await forgotPassword(emailRequest);
        if (!res.data.success)
          (useNuxtApp() as any).$toast.success(res.data.message);
        else await navigateTo({ path: ROUTES_NAME.FORGOT_PASSWORD_SUCCESS });
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }

      loader.hide();
    },

    async resetPass(password: string, email: string, token: string) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const resetPasswordRequest: resetPassword = {
        password,
        email,
        token,
        password_confirmation: password,
      };
      try {
        const res = await resetPass(resetPasswordRequest);
        if (!res.data.success)
          (useNuxtApp() as any).$toast.error(res.data.message);
        else {
          await navigateTo({ path: ROUTES_NAME.LOGIN });
          (useNuxtApp() as any).$toast.success(
            useNuxtApp().$i18n.t("common.message.password_updated")
          );
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }

      loader.hide();
    },

    async getLocationCountries() {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      try {
        const res = await locationCountries();
        if (res.data.success) return res.data.data as [];
        return [];
      } catch (e: any) {
        return [];
      } finally {
        loader.hide();
      }
    },

    async getLocationStates(codeCountry: string) {
      // const loader = (useNuxtApp() as any).$loading.show(useNuxtApp().$configLoading);

      try {
        const res = await locationStates(codeCountry);
        if (res.data.success) return res.data.data as [];
        return [];
      } catch (e: any) {
        return [];
      } finally {
        // loader.hide();
      }
    },

    async getLocationCities(codeState: string) {
      try {
        const res = await locationCities(codeState);
        if (res.data.success) return res.data.data as [];
        return [];
      } catch (e: any) {
        return [];
      }
    },

    async updatePersonalDetail(
      updatePersonalDetailRequest: updatePersonalDetailRequest
    ) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await updatePersonalDetail(updatePersonalDetailRequest);
        if (!res.data.success)
          (useNuxtApp() as any).$toast.error(res.data.message);
        else {
          await this.fetchUser();
          await navigateTo({
            path: ROUTES_NAME.LOBBY,
          });
          (useNuxtApp() as any).$toast.success(
            useNuxtApp().$i18n.t("common.message.user_info_updated")
          );
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }

      loader.hide();
    },

    async sendOtp() {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await sendOtp();
        return true;
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
        return false;
      } finally {
        loader.hide();
      }
    },

    async verifyOtp(otp: string) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await verifyOtp(otp);
        (useNuxtApp() as any).$toast.success(
          useNuxtApp().$i18n.t("common.message.phone_verified")
        );
        this.fetchUser();
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
        return false;
      } finally {
        loader.hide();
      }
      return true;
    },

    async updateUserInfo(updateInfoUserRequest: updatePersonalDetailRequest) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await updateInfoUser(updateInfoUserRequest);
        if (!res.data.success)
          (useNuxtApp() as any).$toast.error(res.data.message);
        else {
          await this.fetchUser();
          (useNuxtApp() as any).$toast.success(
            useNuxtApp().$i18n.t("common.message.user_info_updated")
          );
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      }

      loader.hide();
    },

    async getReferralFriend() {
      try {
        const res = await getReferralFriend();
        return res.data.data;
      } catch (e) {
        return {
          friends_invited: 0,
          friend_qualified: 0,
          rewards_earned: 0,
        };
      }

      return {
        friends_invited: 0,
        friend_qualified: 0,
        rewards_earned: 0,
      };
    },

    async rewardIsClaim() {
      try {
        const res = await rewardIsClaim();
        const tokenCookie = useCustomCookie("token");

        //inject in authent page -> auto go login -> fetch user fail
        if (tokenCookie.value) await this.fetchUser();
        return res.data.data.is_claimed;
      } catch (e) {
        return false;
      }
    },

    // async rewardClaim() {
    //   const loader = (useNuxtApp() as any).$loading.show(useNuxtApp().$configLoading);
    //   try {
    //     const res = await rewardClaim();
    //     await this.fetchUser();
    //     const useWallet = useWalletStore();
    //     await useWallet.setListWallet();
    //     loader.hide();
    //     (useNuxtApp() as any).$toast.success("Reward claimed!");
    //     return res.data.data.is_claimed;
    //   } catch (e: any) {
    //     (useNuxtApp() as any).$toast.error(e.response?.data.message || e.message);
    //     return false
    //   } finally {
    //     loader.hide();
    //   }
    // },

    async updatePassword(updatePasswordRequest: updatePasswordRequest) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      try {
        const res = await updatePassword(updatePasswordRequest);
        loader.hide();
        (useNuxtApp() as any).$toast.success(
          useNuxtApp().$i18n.t("common.message.password_updated_1")
        );
        return true;
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
        return false;
      } finally {
        loader.hide();
      }
    },

    async starInformation() {
      try {
        const res = await starInformation();
        return res.data.data;
      } catch (e: any) {
        return null;
      }
    },

    async listLevel() {
      try {
        const res = await listLevel();
        return res.data.data;
      } catch (e: any) {
        return null;
      }
    },

    async vipSystemStar() {
      try {
        const res = await vipSystemStar();
        return res.data.data;
      } catch (e: any) {
        return null;
      }
    },

    async updateEmail(
      access_token: string | null,
      email: string | null,
      provider_name: string | null,
      username: string,
      visitor_id?: string
    ) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const referenceIdCookie = useCustomCookie("reference_id");
      const affiliateTokenCookie = useCustomCookie("affiliate_token");

      const reference = referenceIdCookie.value || null;
      const affiliate_token = affiliateTokenCookie.value || null;
      const dataRequest: any = {
        email,
        username,
        provider_name,
        access_token,
        reference,
        affiliate_token,
        visitor_id,
      };

      try {
        const res = await updateEmail(dataRequest);
        if (res.data.success) {
          await navigateTo({
            path: ROUTES_NAME.SIGN_UP_WELCOME,
            query: {
              email,
            },
          });
          (useNuxtApp() as any).$toast.success(
            useNuxtApp().$i18n.t("common.message.account_registerd")
          );
        }
      } catch (e: any) {
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      } finally {
        loader.hide();
      }
    },

    async levelInformation() {
      try {
        const res = await levelInformation();
        this.levelInfo = res.data.data;
        return res.data.data;
      } catch (e: any) {
        return null;
      }
    },

    async updateLevelInformation(data: any) {
      this.levelInfo = data;
    },

    async getCashbackInfo() {
      try {
        const res = await cashback();
        this.cashbackInfo = res?.data?.data;
      } catch (e: any) {
        return null;
      }
    },
    async updateLanguage(lang: string) {
      try {
        const res = await changeLanguage();
        if (res.data.success) {
          useNuxtApp().$i18n.locale = lang;
          window.location.reload();
        }
      } catch (e: any) {
        return null;
      }
    },
  },
});
