import { default as _91slug_93QjQBnbhYaBMeta } from "/var/lib/jenkins/workspace/FE/client/pages/blogs/[slug].vue?macro=true";
import { default as indexXwTXzN0yT9Meta } from "/var/lib/jenkins/workspace/FE/client/pages/blogs/index.vue?macro=true";
import { default as tags_46clientMQCICiqvzDMeta } from "/var/lib/jenkins/workspace/FE/client/pages/blogs/tags.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91slug_93DvxdFYBL9QMeta } from "/var/lib/jenkins/workspace/FE/client/pages/categories/[slug].vue?macro=true";
import { default as indexdG7tVOOyVtMeta } from "/var/lib/jenkins/workspace/FE/client/pages/challenges/index.vue?macro=true";
import { default as verifyAuh74rQiptMeta } from "/var/lib/jenkins/workspace/FE/client/pages/email/verify.vue?macro=true";
import { default as indexxkcyYpNcqEMeta } from "/var/lib/jenkins/workspace/FE/client/pages/favorite/index.vue?macro=true";
import { default as indexqB8GvT75elMeta } from "/var/lib/jenkins/workspace/FE/client/pages/forgot-password/index.vue?macro=true";
import { default as send_45successc0Dvt1JnTIMeta } from "/var/lib/jenkins/workspace/FE/client/pages/forgot-password/send-success.vue?macro=true";
import { default as index_46clientus0CeccM65Meta } from "/var/lib/jenkins/workspace/FE/client/pages/gaming-history/index.client.vue?macro=true";
import { default as index4hTyHRhGe6Meta } from "/var/lib/jenkins/workspace/FE/client/pages/index.vue?macro=true";
import { default as indexoF5SKiXm1dMeta } from "/var/lib/jenkins/workspace/FE/client/pages/live-casino/index.vue?macro=true";
import { default as indexVk9d1lFG73Meta } from "/var/lib/jenkins/workspace/FE/client/pages/lobby/index.vue?macro=true";
import { default as indexBDo0c3LSdqMeta } from "/var/lib/jenkins/workspace/FE/client/pages/login/index.vue?macro=true";
import { default as update_45email_46clientEQ2eSWFgZ4Meta } from "/var/lib/jenkins/workspace/FE/client/pages/login/update-email.client.vue?macro=true";
import { default as index_46client6jDxKErxjzMeta } from "/var/lib/jenkins/workspace/FE/client/pages/not-available/index.client.vue?macro=true";
import { default as _91slug_93_46clientTOZJKauEURMeta } from "/var/lib/jenkins/workspace/FE/client/pages/page-detail/[slug].client.vue?macro=true";
import { default as _91token_93DZyk1sqtuiMeta } from "/var/lib/jenkins/workspace/FE/client/pages/password/reset/[token].vue?macro=true";
import { default as indexLdndmfEYw2Meta } from "/var/lib/jenkins/workspace/FE/client/pages/play-again/index.vue?macro=true";
import { default as _91slug_93_46clientHHhfJCC1niMeta } from "/var/lib/jenkins/workspace/FE/client/pages/play-game/[slug].client.vue?macro=true";
import { default as index_46clientesPR7K2MOOMeta } from "/var/lib/jenkins/workspace/FE/client/pages/prizes/index.client.vue?macro=true";
import { default as change_45password_46clientVyUXYB2WTjMeta } from "/var/lib/jenkins/workspace/FE/client/pages/profile/change-password.client.vue?macro=true";
import { default as my_45profile_46clientGC3znqV9NNMeta } from "/var/lib/jenkins/workspace/FE/client/pages/profile/my-profile.client.vue?macro=true";
import { default as _91slug_93OtvdchQMpaMeta } from "/var/lib/jenkins/workspace/FE/client/pages/promotion/[slug].vue?macro=true";
import { default as indexHAk7Uhl60pMeta } from "/var/lib/jenkins/workspace/FE/client/pages/promotion/index.vue?macro=true";
import { default as index_46clientUBY8rf7iw5Meta } from "/var/lib/jenkins/workspace/FE/client/pages/provider/index.client.vue?macro=true";
import { default as index_46clientdcSWFuK77gMeta } from "/var/lib/jenkins/workspace/FE/client/pages/sign-up/index.client.vue?macro=true";
import { default as welcomen49JhgsW1wMeta } from "/var/lib/jenkins/workspace/FE/client/pages/sign-up/welcome.vue?macro=true";
import { default as _91slug_93_46clientwuA84zKF0OMeta } from "/var/lib/jenkins/workspace/FE/client/pages/tournaments/[slug].client.vue?macro=true";
import { default as index_46clientmHiwMKksEVMeta } from "/var/lib/jenkins/workspace/FE/client/pages/tournaments/index.client.vue?macro=true";
import { default as index_46clienthScfQzdydUMeta } from "/var/lib/jenkins/workspace/FE/client/pages/transaction-history/index.client.vue?macro=true";
import { default as indexZL8IIAjVb7Meta } from "/var/lib/jenkins/workspace/FE/client/pages/vip-program/index.vue?macro=true";
export default [
  {
    name: "blogs-slug",
    path: "/blogs/:slug()",
    meta: _91slug_93QjQBnbhYaBMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/blogs/[slug].vue")
  },
  {
    name: "blogs",
    path: "/blogs",
    meta: indexXwTXzN0yT9Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/blogs/index.vue")
  },
  {
    name: "blogs-tags",
    path: "/blogs/tags",
    meta: tags_46clientMQCICiqvzDMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/blogs/tags.client.vue"))
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    meta: _91slug_93DvxdFYBL9QMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/categories/[slug].vue")
  },
  {
    name: "challenges",
    path: "/challenges",
    meta: indexdG7tVOOyVtMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/challenges/index.vue")
  },
  {
    name: "email-verify",
    path: "/email/verify",
    meta: verifyAuh74rQiptMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/email/verify.vue")
  },
  {
    name: "favorite",
    path: "/favorite",
    meta: indexxkcyYpNcqEMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/favorite/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexqB8GvT75elMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-send-success",
    path: "/forgot-password/send-success",
    meta: send_45successc0Dvt1JnTIMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/forgot-password/send-success.vue")
  },
  {
    name: "gaming-history",
    path: "/gaming-history",
    meta: index_46clientus0CeccM65Meta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/gaming-history/index.client.vue"))
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/index.vue")
  },
  {
    name: "live-casino",
    path: "/live-casino",
    meta: indexoF5SKiXm1dMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/live-casino/index.vue")
  },
  {
    name: "lobby",
    path: "/lobby",
    meta: indexVk9d1lFG73Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/lobby/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexBDo0c3LSdqMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/login/index.vue")
  },
  {
    name: "login-update-email",
    path: "/login/update-email",
    meta: update_45email_46clientEQ2eSWFgZ4Meta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/login/update-email.client.vue"))
  },
  {
    name: "not-available",
    path: "/not-available",
    meta: index_46client6jDxKErxjzMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/not-available/index.client.vue"))
  },
  {
    name: "page-detail-slug",
    path: "/page-detail/:slug()",
    meta: _91slug_93_46clientTOZJKauEURMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/page-detail/[slug].client.vue"))
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93DZyk1sqtuiMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/password/reset/[token].vue")
  },
  {
    name: "play-again",
    path: "/play-again",
    meta: indexLdndmfEYw2Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/play-again/index.vue")
  },
  {
    name: "play-game-slug",
    path: "/play-game/:slug()",
    meta: _91slug_93_46clientHHhfJCC1niMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/play-game/[slug].client.vue"))
  },
  {
    name: "prizes",
    path: "/prizes",
    meta: index_46clientesPR7K2MOOMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/prizes/index.client.vue"))
  },
  {
    name: "profile-change-password",
    path: "/profile/change-password",
    meta: change_45password_46clientVyUXYB2WTjMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/profile/change-password.client.vue"))
  },
  {
    name: "profile-my-profile",
    path: "/profile/my-profile",
    meta: my_45profile_46clientGC3znqV9NNMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/profile/my-profile.client.vue"))
  },
  {
    name: "promotion-slug",
    path: "/promotion/:slug()",
    meta: _91slug_93OtvdchQMpaMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/promotion/[slug].vue")
  },
  {
    name: "promotion",
    path: "/promotion",
    meta: indexHAk7Uhl60pMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/promotion/index.vue")
  },
  {
    name: "provider",
    path: "/provider",
    meta: index_46clientUBY8rf7iw5Meta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/provider/index.client.vue"))
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: index_46clientdcSWFuK77gMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/sign-up/index.client.vue"))
  },
  {
    name: "sign-up-welcome",
    path: "/sign-up/welcome",
    meta: welcomen49JhgsW1wMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/sign-up/welcome.vue")
  },
  {
    name: "tournaments-slug",
    path: "/tournaments/:slug()",
    meta: _91slug_93_46clientwuA84zKF0OMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/tournaments/[slug].client.vue"))
  },
  {
    name: "tournaments",
    path: "/tournaments",
    meta: index_46clientmHiwMKksEVMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/tournaments/index.client.vue"))
  },
  {
    name: "transaction-history",
    path: "/transaction-history",
    meta: index_46clienthScfQzdydUMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/transaction-history/index.client.vue"))
  },
  {
    name: "vip-program",
    path: "/vip-program",
    meta: indexZL8IIAjVb7Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/vip-program/index.vue")
  }
]