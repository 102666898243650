import {
  changeSelectedWalletPayload,
  lightnightPayload,
  updateWalletSettingPayload,
  withdrawalPayload,
} from "~/types/schema";

export const getUserWallets = () => {
  const { $api2 } = useNuxtApp();
  return $api2("/api/wallets/user-wallets");
};

export const changeSelectedWallet = (payload: changeSelectedWalletPayload) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/wallets/user-wallets/change-selected-wallet`,
    method: "post",
    data: payload,
  });
};

export const updateWalletSetting = (payload: updateWalletSettingPayload) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/wallets/setting`,
    method: "put",
    data: payload,
  });
};

export const getListWalletCurrenciesRate = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/wallets/entity-wallets/rate`,
    method: "get",
  });
};

export const getListEntityWallets = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/wallets/entity-wallets`,
    method: "get",
  });
};

export const getWalletsDeposit = (entity_wallet_code: string, kind: string) => {
  const { $api } = useNuxtApp();
    return $api.request({
        url: `api/user/wallet-address`,
        method: "get",
        params: {
          entity_wallet_code,
          kind
        }
    });
};

export const requestWithdrawal = (payload: withdrawalPayload) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/user/withdrawal`,
    method: "post",
    data: payload,
  });
};

export const getBtcLightingAddress = (payload: lightnightPayload) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/user/btc-lightning/wallet-address`,
    method: "post",
    data: payload,
  });
};
